export const TokenAddressByChainId = {
  // Mainnet
  1: {
    MANA: '0x0f5d2fb29fb7d3cfee444a200298f468908cc942',
    DAI: '0x6b175474e89094c44da98b954eedeac495271d0f',
    USDT: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    USDC: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
  },
  // Sepolia
  11155111: {
    MANA: '0xfa04d2e2ba9aec166c93dfeeba7427b2303befa9',
    DAI: '0x0000000000000000000000000000000000000000',
    USDT: '0x0000000000000000000000000000000000000000',
    USDC: '0x0000000000000000000000000000000000000000',
  },
  // Arbitrum One
  42161: {
    MANA: '0xfa58c669b855b29f99374d0f160db286849d139b',
    DAI: '0x0000000000000000000000000000000000000000',
    USDT: '0x0000000000000000000000000000000000000000',
    USDC: '0x0000000000000000000000000000000000000000',
  },
}

export const ContractVersion = {
  V1: 'v1',
  V2: 'v2',
}

export const TopicByVersion = {
  [ContractVersion.V1]: {
    RELEASE: '0xfb81f9b30d73d830c3544b34d827c08142579ee75710b490bab0b3995468c565',
    REVOKE: '0x44825a4b2df8acb19ce4e1afba9aa850c8b65cdb7942e2078f27d0b0960efee6',
    TRANSFER_OWNERSHIP: '0x8be0079c531659141344cd1fd0a4f28419497f9722a3daafe3b4186f6b6457e0',
    PAUSED: '0x0000000000000000000000000000000000000000000000000000000000000000',
    UNPAUSED: '0x0000000000000000000000000000000000000000000000000000000000000000',
  },
  [ContractVersion.V2]: {
    RELEASE: '0xb21fb52d5749b80f3182f8c6992236b5e5576681880914484d7f4c9b062e619e',
    REVOKE: '0x44825a4b2df8acb19ce4e1afba9aa850c8b65cdb7942e2078f27d0b0960efee6',
    TRANSFER_OWNERSHIP: '0x8be0079c531659141344cd1fd0a4f28419497f9722a3daafe3b4186f6b6457e0',
    PAUSED: '0x62e78cea01bee320cd4e420270b5ea74000d11b0c9f74754ebdbfc544b05a258',
    UNPAUSED: '0x5db9ee0a495bf2e6ff9c91a7834c1ba4fdd244a5e8aa4e537bd38aeae4b073aa',
  },
}
